import { createSlice } from "@reduxjs/toolkit";

const paymentInfoSlice = createSlice({
  name: "paymentInfoSlice",
  initialState: {
    chartData: [],
  },
  reducers: {
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
  },
});

export const { setChartData } = paymentInfoSlice.actions;
export default paymentInfoSlice.reducer;
