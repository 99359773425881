import React from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import ThankingImage from "../../assets/images/login/ThankingImage.png";
import facebookIcon from "../../assets/images/login/fb.png";
import linkedinIcon from "../../assets/images/login/LinkedIn.png";
import youtubeIcon from "../../assets/images/login/YouTube.png";

function PaymentFailurePage() {
  const navigate = useNavigate();
  return (
    // <div className="paymentFailure">
    //   <h2>Payment Failure Page</h2>
    //   <CButton
    //     color="dark"
    //     shape="rounded-0"
    //     className="accordion-saveBtn addProject-saveBtn"
    //     style={{ marginLeft: "1rem" }}
    //     onClick={() => navigate("/signin")}
    //   >
    //     Back to Sign Up
    //   </CButton>
    // </div>
    <div className="bg-light min-vh-100 d-flex flex-column justify-content-center SignInPage-Container">
      {/* <LoginHeader /> */}
      <div style={{ textAlign: "center", width: "100%" }}>
        <CRow className="justify-content-center">
          <CCol md={8} className="form">
            <CRow className="d-flex align-items-center">
              <div className="thankingPage">
                <img src={ThankingImage} className="SignUpImage" />
                <h2 className="thankingPage-heading">OOPS! Payment Failure</h2>
                <p className="thankingPage-para">
                  Congratulations! You have successfully signed up to our
                  website. Welcome to ArchiFlask! We're thrilled to have you on
                  board. Explore, connect, and enjoy all the exciting features
                  our platform has to offer. We’ve also sent you an email with a
                  verification link which we request you to click and complete
                  the sign-up process. If you have any questions or need
                  assistance, feel free to reach out to us.
                </p>
                <CButton
                  color="dark"
                  shape="rounded-pill"
                  className="form-inputGroup_Button thankingPage-button"
                  onClick={() => {
                    navigate("/dashboard");
                    // window.location.href = "/signin";
                  }}
                >
                  Get started
                </CButton>
                <div className="svg">
                  <img src={facebookIcon} className="svgIcon" />
                  <img src={linkedinIcon} className="svgIcon" />
                  <img src={youtubeIcon} className="svgIcon" />
                </div>
              </div>
            </CRow>
          </CCol>
        </CRow>
      </div>
      {/* <LoginFooter /> */}
    </div>
  );
}

export default PaymentFailurePage;
