import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../components/webpages/webpage.scss";
import "../../webassets/vendor/bootstrap-icons/bootstrap-icons.scss";
import "../../webassets/vendor/glightbox/css/glightbox.min.scss";
import "../../webassets/vendor/remixicon/remixicon.scss";
import "../../webassets/vendor/swiper/swiper-bundle.min.scss";
import "../../webassets/css/style.scss";

import { Navigate, useNavigate } from "react-router-dom";
import BasicPlanCard from "./BasicPlanCard";
import StarterPlanCard from "./StarterPlanCard";
import BuisnessPlanCard from "./BuisnessPlanCard";
import { useSelector } from "react-redux";
import PaymentAddForm from "../PaymentAddForm";
import { useDispatch } from "react-redux";
import { setPricingModalVisible } from "src/redux/slices/PlanSlice";

function PlansUI() {
  const dispatch = useDispatch();
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="plans-modal-overlay"
      onClick={() => {
        dispatch(setPricingModalVisible(false));
        console.log("pricing modal close");
      }}
    >
      <div className="plans-modal">
        <div>
          <header className="section-header">
            <h2 style={{ fontSize: "1.2rem", marginBottom: "0rem" }}>
              Choose your Plan
            </h2>
          </header>
          <button
            onClick={() => {
              dispatch(setPricingModalVisible(false));
              console.log("close button clicked");
            }}
            className="close-button"
            style={{
              position: "absolute",
              right: "-2px",
              top: "-8px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
        </div>
        <div
          className="webpage plans-modal-body"
          onClick={(e) => e.stopPropagation()}
        >
          <section
            id="pricing"
            className="pricing"
            style={{ padding: "20px 0px 0px 0px" }}
          >
            <div className="container" data-aos="fade-up">
              <div className="row gy-4" data-aos="fade-left">
                <BasicPlanCard />
                <StarterPlanCard />
                <BuisnessPlanCard />
                {/* <PaymentAddForm /> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PlansUI;
