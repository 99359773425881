import { createSlice } from "@reduxjs/toolkit";

const UserRegistrationSlice = createSlice({
  name: "UserRegistration",
  initialState: {
    name: "",
    mail: "",
    password: "",
    company: "",
    phone: "",
    loader: false,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setMail: (state, action) => {
      state.mail = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const {
  setName,
  setMail,
  setPassword,
  setCompany,
  setPhone,
  setLoader,
} = UserRegistrationSlice.actions;
export default UserRegistrationSlice.reducer;
