import React from "react";

function LoginFooter() {
  return (
    <footer
      className="text-center text-lg-start w-100"
      style={{ position: "absolute", bottom: "5px" }}
    >
      <div className="text-center footer-copyrights">
        Copyright @ArchiFlask{new Date().getFullYear()}
      </div>
    </footer>
  );
}

export default LoginFooter;
