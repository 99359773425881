import { createSlice } from "@reduxjs/toolkit";

const photoSlice = createSlice({
  name: "Photos",
  initialState: {
    materialspecificMSID: "",
    materialspecificCategory: "",
  },
  reducers: {
    SetMaterialSpecificMSID: (state, action) => {
      state.materialspecificMSID = action.payload;
    },
    SetMaterialSpecificCategory: (state, action) => {
      state.materialspecificCategory = action.payload;
    },
  },
});

export const { SetMaterialSpecificMSID,SetMaterialSpecificCategory } = photoSlice.actions;
export default photoSlice.reducer;
