import React from "react";

const TermsPolicy = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        margin: "20px",
      }}
    >
      <h1>Terms and Conditions</h1>
      <p>Last Updated: 5th August 2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Wallzehn Technologies Pvt. Ltd. (“Company,” “we,” “our,” or
        “us”). These Terms and Conditions (the “Terms”) govern your access to
        and use of our SaaS platform, ArchiFlask (the “Service”). By accessing
        or using our Service, you agree to be bound by these Terms and our
        Privacy Policy. If you do not agree to these Terms, you may not access
        or use the Service.
      </p>
      <h2>2. Definitions</h2>
      <p>“User” means any person who accesses or uses the Service.</p>
      <p>“Account” refers to the account created by you to use the Service.</p>
      <p>“Subscription” refers to the paid access plan to the Service.</p>
      <p>
        “Content” refers to any data, information, text, software, or other
        materials that you or other users submit or upload to the Service.
      </p>
      <h2>3. Account Registration</h2>
      <p>
        <strong>3.1.</strong> To use the Service, you must create an Account by
        providing accurate and complete information.
      </p>
      <p>
        <strong>3.2.</strong> You are responsible for maintaining the
        confidentiality of your Account credentials and for all activities that
        occur under your Account.
      </p>
      <p>
        <strong>3.3.</strong> You agree to notify us immediately of any
        unauthorized use of your Account or any other breach of security.
      </p>
      <h2>4. Subscription and Payment</h2>
      <p>
        <strong>4.1.</strong> The Service is offered on a subscription basis.
        You may choose from various subscription plans, as described on our
        website.
      </p>
      <p>
        <strong>4.2.</strong> Subscription fees are billed in advance and are
        non-refundable except as required by law.
      </p>
      <p>
        <strong>4.3.</strong> We may change subscription fees or billing methods
        with prior notice. Continued use of the Service after any such changes
        constitutes your acceptance of the new fees.
      </p>
      <p>
        <strong>4.4.</strong> Payments must be made using a valid payment
        method. You authorize us to charge the payment method on file for the
        subscription fees.
      </p>
      <h2>5. License and Use</h2>
      <p>
        <strong>5.1.</strong> Subject to these Terms, we grant you a limited,
        non-exclusive, non-transferable license to access and use the Service
        for your internal business purposes.
      </p>
      <p>You may not:</p>
      <ul>
        <li>Modify, adapt, or create derivative works of the Service.</li>
        <li>
          Reproduce, distribute, or publicly display any part of the Service
          without our prior written consent.
        </li>
        <li>
          Use the Service for illegal purposes or to infringe on the rights of
          others.
        </li>
      </ul>
      <h2>6. User Content</h2>
      <p>
        <strong>6.1.</strong> You retain ownership of all Content you upload to
        the Service.
      </p>
      <p>
        <strong>6.2.</strong> By uploading Content to the Service, you grant us
        a worldwide, royalty-free, non-exclusive license to use, display, and
        process your Content for the purpose of providing the Service.
      </p>
      <p>
        <strong>6.3.</strong> You are responsible for ensuring that your Content
        complies with all applicable laws and does not infringe on any
        third-party rights.
      </p>
      <h2>7. Data Privacy and Security</h2>
      <p>
        <strong>7.1.</strong> We are committed to protecting your data. Please
        refer to our Privacy Policy for information about how we collect, use,
        and protect your personal information.
      </p>
      <p>
        <strong>7.2.</strong> We implement reasonable security measures to
        protect your data, but we cannot guarantee absolute security. You are
        responsible for maintaining the confidentiality of your login
        credentials and for all activities under your Account.
      </p>
      <h2>8. Service Availability</h2>
      <p>
        <strong>8.1.</strong> We strive to provide continuous and uninterrupted
        access to the Service, but we do not guarantee that the Service will
        always be available or error-free.
      </p>
      <p>
        <strong>8.2.</strong> We may perform maintenance or updates to the
        Service, which could temporarily affect availability.
      </p>
      <h2>9. Termination</h2>
      <p>
        <strong>9.1.</strong> We may suspend or terminate your access to the
        Service if you breach these Terms or engage in unlawful activities.
      </p>
      <p>
        <strong>9.2.</strong> You may terminate your subscription at any time by
        following the cancellation procedures on our website. Termination will
        be effective at the end of your current billing cycle.
      </p>
      <p>
        <strong>9.3.</strong> Upon termination, you will lose access to your
        Account and all Content may be deleted.
      </p>
      <h2>10. Limitation of Liability</h2>
      <p>
        <strong>10.1.</strong> To the fullest extent permitted by law, Wallzehn
        Technologies Pvt. Ltd. and its affiliates shall not be liable for any
        indirect, incidental, special, consequential, or punitive damages
        arising out of or related to your use of the Service.
      </p>
      <p>
        <strong>10.2.</strong> Our total liability for any claim related to the
        Service shall not exceed the amount paid by you for the Service in the
        six (6) months preceding the claim.
      </p>
      <h2>11. Indemnification</h2>
      <p>
        You agree to indemnify and hold Wallzehn Technologies Pvt. Ltd., its
        affiliates, and their respective officers, directors, employees, and
        agents harmless from any claims, liabilities, damages, losses, or
        expenses arising out of your use of the Service or any violation of
        these Terms.
      </p>
      <h2>12. Dispute Resolution</h2>
      <p>
        <strong>12.1.</strong> Any disputes arising from these Terms or your use
        of the Service shall be resolved through binding arbitration in
        accordance with the rules of Indian Council of Arbitration.
      </p>
      <p>
        <strong>12.2.</strong> The arbitration shall be conducted in Tamil Nadu,
        India. Judgment on the arbitration award may be entered in any court
        having jurisdiction.
      </p>
      <h2>13. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of Tamil Nadu, India, without regard to its conflict of law
        principles.
      </p>
      <h2>14. Changes to These Terms</h2>
      <p>
        <strong>14.1.</strong> We may update these Terms from time to time. We
        will notify you of significant changes by posting the revised Terms on
        our website.
      </p>
      <p>
        <strong>14.2.</strong> Your continued use of the Service after the
        posting of revised Terms constitutes your acceptance of the new Terms.
      </p>
      <h2>15. Miscellaneous</h2>
      <p>
        <strong>15.1.</strong> If any provision of these Terms is found to be
        invalid or unenforceable, the remaining provisions shall continue in
        full force and effect.
      </p>
      <p>
        <strong>15.2.</strong> These Terms constitute the entire agreement
        between you and Wallzehn Technologies Pvt. Ltd. regarding your use of
        the Service and supersede all prior agreements and understandings.
      </p>
      <h2>16. Contact Information</h2>
      <p>
        For any questions or concerns regarding these Terms, please contact us
        at:
      </p>
      <p>
        Wallzehn Technologies Pvt. Ltd.
        <br />
        18, 2A, Quanta Trinity, West Road, West CIT Nagar, Chennai 600035
        <br />
        <a href="mailto:info@wallzehn.com">info@wallzehn.com</a>
        <br />
        91-7708035349
      </p>
    </div>
  );
};

export default TermsPolicy;
