import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        margin: "20px",
      }}
    >
      <h1>Privacy Policy</h1>
      <p>Last Updated: 5th August 2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Wallzehn Technologies Pvt. Ltd. (“Company,” “we,” “our,” or
        “us”). We value your privacy and are committed to protecting your
        personal information. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your data when you use our SaaS platform,
        ArchiFlask (the “Service”). By accessing or using our Service, you agree
        to the practices described in this Privacy Policy.
      </p>
      <h2>2. Information We Collect</h2>
      <h3>2.1. Personal Information</h3>
      <p>
        We may collect personal information that you provide to us directly,
        such as:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
      </ul>
      <h3>2.2. Usage Data</h3>
      <p>We collect data about how you use our Service, including:</p>
      <ul>
        <li>IP address</li>
        <li>Browser type and version</li>
        <li>Device information</li>
        <li>Usage activity and interactions with the Service</li>
      </ul>
      <h3>2.3. Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to enhance your
        experience and analyze usage patterns. Cookies are small files placed on
        your device that allow us to remember your preferences and improve the
        Service. You can control cookie settings through your browser.
      </p>
      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, maintain, and improve our Service</li>
        <li>Process transactions and manage billing</li>
        <li>
          Communicate with you about your Account, updates, and promotions
        </li>
        <li>Analyze usage and performance to enhance the Service</li>
        <li>Respond to customer support requests</li>
      </ul>
      <h2>4. Data Sharing and Disclosure</h2>
      <h3>4.1. Service Providers</h3>
      <p>
        We may share your information with third-party service providers who
        perform services on our behalf, such as payment processors, hosting
        providers, and analytics services. These providers are bound by
        confidentiality agreements and are only authorized to use your
        information to perform services for us.
      </p>
      <h3>4.2. Legal Requirements</h3>
      <p>
        We may disclose your information if required by law or in response to
        valid requests by public authorities (e.g., to comply with a subpoena,
        legal process, or government request).
      </p>
      <h3>4.3. Business Transfers</h3>
      <p>
        In the event of a merger, acquisition, or other business transaction,
        your information may be transferred to the acquiring entity. We will
        notify you of any such change in ownership or control of your personal
        information.
      </p>
      <h2>5. Data Security</h2>
      <p>
        We implement reasonable and appropriate security measures to protect
        your information from unauthorized access, alteration, disclosure, or
        destruction. However, no method of transmission over the internet or
        electronic storage is completely secure, and we cannot guarantee
        absolute security.
      </p>
      <h2>6. Data Retention</h2>
      <p>
        We retain your personal information only as long as necessary to fulfill
        the purposes outlined in this Privacy Policy or as required by law. When
        your information is no longer needed, we will securely delete or
        anonymize it.
      </p>
      <h2>7. Your Rights and Choices</h2>
      <h3>7.1. Access and Correction</h3>
      <p>
        You have the right to access and update your personal information. You
        can do this by logging into your Account or contacting us directly.
      </p>
      <h3>7.2. Opt-Out</h3>
      <p>
        You may opt out of receiving marketing communications from us by
        following the unsubscribe instructions included in each email or by
        contacting us directly.
      </p>
      <h3>7.3. Data Subject Requests</h3>
      <p>
        If you are a resident of a jurisdiction with data protection laws, you
        may have additional rights, such as the right to request the deletion of
        your data or to object to processing. To exercise these rights, please
        contact us using the information provided below.
      </p>
      <h2>8. International Transfers</h2>
      <p>
        If you are accessing our Service from outside [Your Country], please be
        aware that your information may be transferred to, stored, and processed
        in [Your Country] or other countries where our service providers are
        located. We take appropriate measures to ensure that your data is
        handled in accordance with this Privacy Policy and applicable data
        protection laws.
      </p>
      <h2>9. Children’s Privacy</h2>
      <p>
        Our Service is not intended for individuals under the age of 16. We do
        not knowingly collect personal information from children under 16. If we
        become aware that we have collected such information, we will take steps
        to delete it promptly.
      </p>
      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the revised policy on our website
        with the updated effective date. Your continued use of the Service after
        the changes constitutes your acceptance of the new Privacy Policy.
      </p>
      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p>
        Wallzehn Technologies Pvt. Ltd.
        <br />
        18, 2A, Quanta Trinity, West Road, West CIT Nagar, Chennai 600035
        <br />
        <a href="mailto:info@wallzehn.com">info@wallzehn.com</a>
        <br />
        91-7708035349
      </p>
    </div>
  );
};

export default PrivacyPolicy;
