import { createSlice } from "@reduxjs/toolkit";

const taskBoardSlice = createSlice({
  name: "taskBoard",
  initialState: {
    teamMembers: [],
    teamMemberId: "",
    tasksForProjects: [],
    tasksForTeamMember: [],
    projectsTodo: [],
    projectsInProgress: [],
    projectsOnHold: [],
    projectsCompleted: [],
    teamMembersTodo: [],
    teamMembersInProgress: [],
    teamMembersOnHold: [],
    teamMembersCompleted: [],
    assigneeProjects: [],
  },
  reducers: {
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
    setTeamMemberId: (state, action) => {
      state.teamMemberId = action.payload;
    },
    setTasksForProjects: (state, action) => {
      state.tasksForProjects = action.payload;
    },
    setTasksForTeamMember: (state, action) => {
      state.tasksForTeamMember = action.payload;
    },
    setProjectsTodo: (state, action) => {
      state.projectsTodo = action.payload;
    },
    setProjectsInProgress: (state, action) => {
      state.projectsInProgress = action.payload;
    },
    setProjectsOnHold: (state, action) => {
      state.projectsOnHold = action.payload;
    },
    setProjectsCompleted: (state, action) => {
      state.projectsCompleted = action.payload;
    },
    setTeamMembersTodo: (state, action) => {
      state.teamMembersTodo = action.payload;
    },
    setTeamMembersInProgress: (state, action) => {
      state.teamMembersInProgress = action.payload;
    },
    setTeamMembersOnHold: (state, action) => {
      state.teamMembersOnHold = action.payload;
    },
    setTeamMembersCompleted: (state, action) => {
      state.teamMembersCompleted = action.payload;
    },
    setAssigneeProjects: (state, action) => {
      state.assigneeProjects = action.payload;
    },
  },
});

export const {
  setTeamMembers,
  setTeamMemberId,
  setTasksForProjects,
  setTasksForTeamMember,
  setProjectsTodo,
  setProjectsInProgress,
  setProjectsOnHold,
  setProjectsCompleted,
  setTeamMembersTodo,
  setTeamMembersInProgress,
  setTeamMembersOnHold,
  setTeamMembersCompleted,
  setAssigneeProjects,
} = taskBoardSlice.actions;
export default taskBoardSlice.reducer;
