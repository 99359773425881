import React, { useState } from "react";
import { CButton } from "@coreui/react";
import ThankingImage from "../../assets/images/login/ThankingImage.png";
import facebookIcon from "../../assets/images/login/fb.png";
import linkedinIcon from "../../assets/images/login/LinkedIn.png";
import youtubeIcon from "../../assets/images/login/YouTube.png";
import { useDispatch } from "react-redux";
import axios from "axios";
import API_URL from "src/utils/urls";
import { useSelector } from "react-redux";
import { setLoader } from "src/redux/slices/UserRegistrationSlice";
import { setAccessToken, setUserName } from "src/redux/slices/DashboardSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import cryptoJs from "crypto-js";
import { useNavigate } from "react-router-dom";

function ThankingPage() {
  // redux hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // sign up payment session storages
  const signUpAID = localStorage.getItem("signUpAID");
  const signUpTxnId = localStorage.getItem("signUpTxnId");
  const signUpTotalAmount = localStorage.getItem("signUpTotalAmount");
  const signUpProductinfo = localStorage.getItem("signUpProductinfo");
  const signUpMail = localStorage.getItem("signUpMail");
  const signUpName = localStorage.getItem("signUpName");
  const signUpSurl = localStorage.getItem("signUpSurl");
  const signUpFurl = localStorage.getItem("signUpFurl");
  const signUpHash = localStorage.getItem("signUpHash");
  const signUpPhone = localStorage.getItem("signUpPhone");

  // redux states
  const selectedPlan = localStorage.getItem("selectedPlan");
  const selectedPlanDuration = localStorage.getItem("selectedPlanDuration");
  const email = localStorage.getItem("email");
  // const username = localStorage.getItem("username");
  const username = localStorage.getItem("Regusername");
  const password = localStorage.getItem("password");
  const company = localStorage.getItem("company");
  const txnId = localStorage.getItem("txnId");
  const phone = localStorage.getItem("phone");

  const [AID, setAID] = useState("");

  // function has SHA512 hash string
  const sha512 = (value) => {
    const hash = cryptoJs.SHA512(value);
    return hash.toString(cryptoJs.enc.Hex);
  };

  const signUpPaymentAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/postPayuPaymentData`, {
          AID: signUpAID,
          txnid: signUpTxnId,
          productinfo: signUpProductinfo,
          amount: signUpTotalAmount,
          email: signUpMail,
          firstname: signUpName,
          surl: signUpSurl,
          furl: signUpFurl,
          hash: signUpHash,
          phone: signUpPhone,
        })
        .then(function (response) {
          console.log("post payU payment data success response:", response);
          if ((response.data = "success")) {
            // sessionStorage.setItem("selectedPlanDuration", null);
            // sessionStorage.setItem("loginPage", null);
            updatePaymentStatus();
          }
        })
        .catch(function (error) {
          console.log("post payU payment data failure response:", error);
        });
    } catch (error) {
      console.log("error occured:", error);
    }
  };

  const signUpData = async () => {
    await axios({
      method: "POST",
      url: `${API_URL}signup`,
      data: {
        name: username,
        email: email,
        password: sha512(password),
        company: company,
        plan: selectedPlan,
        planDuration: Number(selectedPlanDuration),
        mobile: signUpPhone,
      },
    }).then(
      (res) => {
        console.log(res);
        // setLoading(false);
        dispatch(setLoader(false));
        toast.success("Signed Up Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("AID", res.data.AID);
        localStorage.setItem("UName", res.data.name);
        localStorage.setItem("userRole", "Administrator");
        localStorage.setItem("parentId", res.data.AID);
        dispatch(setAccessToken(res.data.AID));
        dispatch(setUserName(res.data.name));
        setAID(res.data.AID);
        localStorage.setItem("signUpAID", null);
        localStorage.setItem("signUpProductinfo", null);
        localStorage.setItem("signUpTotalAmount", null);
        localStorage.setItem("signUpMail", null);
        localStorage.setItem("signUpName", null);
        localStorage.setItem("signUpSurl", null);
        localStorage.setItem("signUpFurl", null);
        localStorage.setItem("signUpPhone", null);
        localStorage.setItem("signUpHash", null);
        // localStorage.setItem("signUpTxnId", "");
        localStorage.removeItem("signUpTxnId");
        // dispatch(setLoginPage("thanking"));
        // setLogin("thanking");
      },
      (err) => {
        console.log(err);
        alert("error found", err);
        // setLoading(false);
        dispatch(setLoader(false));
      }
    );
  };

  const updatePaymentStatus = async () => {
    await axios
      .post(`${API_URL}/updatePaymentStatus`, {
        AID: signUpAID,
        txnid: signUpTxnId,
        status: "success",
      })
      .then(function (response) {
        console.log("update payment after success ", response);
        if (response.data == "success") {
          signUpData();
        }
      })
      .catch(function (error) {
        console.log(
          "while update payment status to success, error occurs:",
          error
        );
      });
  };

  // useEffect(() => {
  //   selectedPlan != "Free" && signUpData();
  // }, []);

  useEffect(() => {
    // selectedPlan !== "Free" && AID !== "" && updatePaymentStatus();
    if (selectedPlan !== "Free" && AID == "") {
      // signUpPaymentAPI();
      signUpData();
    }
  }, []);

  return (
    <div className="thankingPage">
      <img src={ThankingImage} className="SignUpImage" />
      <h2 className="thankingPage-heading">Thank you for signing up !</h2>
      <p className="thankingPage-para">
        Congratulations! You have successfully signed up to our website. Welcome
        to ArchiFlask! We're thrilled to have you on board. Explore, connect,
        and enjoy all the exciting features our platform has to offer. We’ve
        also sent you an email with a verification link which we request you to
        click and complete the sign-up process. If you have any questions or
        need assistance, feel free to reach out to us.
      </p>
      <CButton
        color="dark"
        shape="rounded-pill"
        className="form-inputGroup_Button thankingPage-button"
        onClick={() => {
          localStorage.removeItem("loginPage");
          navigate("/dashboard");
          // window.location.href = "/dashboard";
        }}
      >
        Get started
      </CButton>
      <div className="svg">
        <img src={facebookIcon} className="svgIcon" />
        <img src={linkedinIcon} className="svgIcon" />
        <img src={youtubeIcon} className="svgIcon" />
      </div>
    </div>
  );
}

export default ThankingPage;
