import React from "react";
import banner_9 from "../../../../webassets/img/pricing-business.png";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedPlanAmount,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";

function BuisnessPlanCard() {
  const dispatch = useDispatch();
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  const showBuyAdditionalUsersModal = useSelector(
    (state) => state.plans.showBuyAdditionalUsersModal
  );
  return (
    <div
      className={`${
        paymentModalVisible || showBuyAdditionalUsersModal
          ? "col-lg-8"
          : "col-lg-4"
      } col-md-6 paymentForm-allUICard`}
      data-aos="zoom-in"
      data-aos-delay={300}
    >
      <div
        className="box paymentForm-box"
        // style={{ height: `${showBuyAdditionalUsersModal && "90%"}` }}
      >
        <span className="featured">Featured</span>
        <h3 style={{ color: "#ff901c" }} className="mb-0">
          Business Plan
        </h3>
        <div className="price">
          <sup>₹</sup>5999<span> +GST / mo</span>
        </div>
        <img src={banner_9} className="img-fluid" alt="" />
        <ul>
          <li>2 Administrators</li>
          <li>6 Team Members</li>
          <li>Unlimited Mobile App Accounts</li>
          <li>Unlimited Projects</li>
          <li>10GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹250/mo</li>
          <li>Add Administrator @ ₹1499/mo</li>
          <li>Add Team Member @ ₹799/mo</li>
        </ul>
        {selectedPlan == null && !showBuyAdditionalUsersModal ? (
          <a
            href="#"
            className="btn-buy"
            onClick={() => {
              dispatch(setSelectedPlan("Business"));
              dispatch(setSelectedPlanAmount(5999));
              dispatch(setPricingModalVisible(false));
              dispatch(setPaymentModalVisible(true));
              localStorage.setItem("selectedPlan", "Business");
            }}
          >
            Select
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default BuisnessPlanCard;
