import { CButton, CCol, CRow, CSpinner } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Sliders from "./Slider";
import {
  faCircleUser,
  faEye,
  faEyeSlash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setPlan,
  setPlanAdditionalLicenses,
  setPlanEndDate,
  setPlanGBAvailable,
  setPlanGBUsed,
  setPlanIsBlocked,
  setPlanLastPaymentDate,
  setPlanStartDate,
  setUserName,
} from "src/redux/slices/DashboardSlice";
import { ToastContainer, toast } from "react-toastify";
import API_URL from "src/utils/urls";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import cryptoJs from "crypto-js";
import { Phone } from "@mui/icons-material";

function SignIn() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [pwdState, setPwdState] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePwdState = () => {
    setPwdState(!pwdState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    getData();
    // Checkmail();
  };

  // const Checkmail = () => {
  //   let params = {
  //     email: mail,
  //   };
  //   try {
  //     axios
  //       .get("https://apiwallzehn.azurewebsites.net/checkEmail", {
  //         params: params,
  //       })
  //       .then((response) => {
  //         setLoading(false);
  //         const newItems = response.data.result;
  //         if (newItems == "no") {
  //           toast.error("Please Enter Correct Mail/Password", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         } else {
  //           getData();
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("An unexpected error occurred:", error);
  //   }
  // };

  // function has SHA512 hash string
  const sha512 = (value) => {
    const hash = cryptoJs.SHA512(value);
    return hash.toString(cryptoJs.enc.Hex);
  };

  const getData = async () => {
    try {
      await axios({
        method: "POST",
        url: `${API_URL}login`,
        data: {
          user: mail,
          password: sha512(password),
        },
      }).then(
        (res) => {
          const result = JSON.stringify(res.data.result);
          const parsedResult = JSON.parse(result);
          if (parsedResult === "Success") {
            localStorage.setItem("AID", res.data.ID);
            localStorage.setItem("UName", res.data.name);
            localStorage.setItem("userRole", res.data.role);
            localStorage.setItem("parentId", res.data.parent);
            localStorage.setItem("phone", res.data.mobile);
            localStorage.setItem("mail", res.data.email);

            // plans
            localStorage.setItem("plan", res.data.plan.plan);
            localStorage.setItem("planStartDate", res.data.plan.startdate);
            localStorage.setItem("planEndDate", res.data.plan.enddate);
            localStorage.setItem("planGBAvailable", res.data.plan.GBAvailable);
            localStorage.setItem("planGBUsed", res.data.plan.GBUsed);
            localStorage.setItem(
              "planAdditionalLicenses",
              res.data.plan.additionalLicenses
            );
            localStorage.setItem("planIsBlocked", res.data.isBlocked);
            localStorage.setItem(
              "planLastPaymentDate",
              res.data.plan.lastPaymentDate
            );

            console.log("session storage in login", res.data);
            dispatch(setAccessToken(res.data.AID));
            dispatch(setUserName(res.data.name));
            dispatch(setPlan(res.data.plan.plan));
            dispatch(setPlanStartDate(res.data.plan.startdate));
            dispatch(setPlanEndDate(res.data.plan.enddate));
            dispatch(setPlanGBAvailable(res.data.plan.GBAvailable));
            dispatch(setPlanGBUsed(res.data.plan.GBUsed));
            dispatch(
              setPlanAdditionalLicenses(res.data.plan.additionalLicenses)
            );
            dispatch(setPlanIsBlocked(res.data.isBlocked));
            dispatch(setPlanLastPaymentDate(res.data.plan.lastPaymentDate));

            // window.location.href = res.data.isBlocked
            //   ? "/mywallzehnspace"
            //   : "/dashboard";
            navigate(res.data.isBlocked ? "/mywallzehnspace" : "/dashboard");
          } else {
            setLoading(false);
            toast.error("Please Enter Correct Mail/Password", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        },
        (err) => {
          console.log(err);
          // alert(err);
          setLoading(false);
        }
      );
    } catch (error) {
      // console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div style={{ height: "30rem" }}>
          <CSpinner style={{ position: "absolute", top: "50%", left: "48%" }} />
        </div>
      ) : (
        <>
          <CRow className="align-items-center justify-content-around">
            <CCol
              md={6}
              className="p-4 d-flex flex-column align-items-center form-container"
            >
              <h2 className="form-heading">User Login</h2>
              <div className="d-flex flex-column align-items-center justify-content-around h-100">
                <form onSubmit={handleSubmit}>
                  <div className="form-inputGroup">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={(e) => setMail(e.target.value.toLowerCase())}
                      className="login-input"
                      autocomplete="off"
                      required
                    />
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      className="form-inputGroup_Icon"
                    />
                  </div>
                  <div className="form-inputGroup">
                    <input
                      type={pwdState ? "password" : "text"}
                      name="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-input"
                      autocomplete="off"
                      required
                    />
                    <FontAwesomeIcon
                      icon={pwdState ? faEye : faEyeSlash}
                      className="form-inputGroup_Icon"
                      style={{ cursor: "pointer" }}
                      onClick={togglePwdState}
                    />
                  </div>
                  <p className="login-haveTrouble">
                    Having trouble in sign in?
                  </p>
                  {/* <a href="/dashboard"> */}
                  <CButton
                    color="dark"
                    shape="rounded-pill"
                    className="form-inputGroup_Button"
                    type="submit"
                    disabled={mail != "" && password != "" ? false : true}
                  >
                    Sign In
                  </CButton>
                  {/* </a> */}
                </form>
                <div className="form-inputGroup_Para w-100">
                  <span>Don't have an account?</span>
                  <a
                    className="form-inputGroup_Para-link"
                    onClick={() => {
                      dispatch(setLoginPage("signUp"));
                    }}
                  >
                    Sign Up here
                  </a>
                </div>
              </div>

              {/* <span className="login-or">--or--</span> */}
              {/* <button className="form-inputGroup_googleBtn">
              <i className="fab fa-google fa-2x"></i>
              Sign in with Google
            </button> */}
            </CCol>
            <div className="vr"></div>
            <CCol md={5} className="text-center">
              <Sliders />
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
}

export default SignIn;
