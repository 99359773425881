import React from "react";
import banner_7 from "../../webassets/img/pricing-free.png";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedPlanAmount,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";
import { setLoginPage } from "src/redux/slices/LoginSlice";
// import signUpData from "src/utils/loginFunctions";
import { setLoader } from "src/redux/slices/UserRegistrationSlice";
import { signUpData } from "src/utils/loginFunctions";

function BasicPlanCard() {
  const dispatch = useDispatch();
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);
  const username = useSelector((state) => state.userRegistration.name);
  const email = useSelector((state) => state.userRegistration.mail);
  const password = useSelector((state) => state.userRegistration.password);
  const company = useSelector((state) => state.userRegistration.company);
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );

  const handleSelectedPlan = () => {
    dispatch(setSelectedPlan("Free"));
    dispatch(setSelectedPlanAmount(0));
    dispatch(setPricingModalVisible(false));
    dispatch(setPaymentModalVisible(false));
    dispatch(setLoader(true));
    localStorage.setItem("selectedPlan", "Free");
    localStorage.setItem("selectedPlanDuration", "12");
    const plan = "Free";
    const planDuration = 12;
    const mobile = "";
    signUpData(
      dispatch,
      username,
      email,
      password,
      company,
      plan,
      planDuration,
      mobile
    );
  };

  return (
    <div
      className={`${
        paymentModalVisible ? "col-lg-8" : "col-lg-4"
      } col-md-6 plans-modal_card`}
      data-aos="zoom-in"
      data-aos-delay={100}
      style={{ padding: "0rem 1rem 0rem 1rem" }}
    >
      <div className="box">
        <h3 style={{ color: "#07d5c0" }} className="mb-0">
          Free Plan
        </h3>
        <div className="price">
          <sup>₹</sup>0<span> / mo</span>
        </div>
        <img src={banner_7} className="img-fluid" alt="" />
        <ul>
          <li>1 Administrator</li>
          <li>1 Team Member</li>
          <li>1 Mobile App Account</li>
          <li>1 Project</li>
          <li>500MB Cloud Storage</li>
          <li className="na">Additional Storage</li>
          <li className="na">Additional Administrators</li>
          <li className="na">Add Team Members</li>
        </ul>
        {selectedPlan == null ? (
          <a href="#" className="btn-buy" onClick={handleSelectedPlan}>
            Select
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default BasicPlanCard;
