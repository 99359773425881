import React, { useState } from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import ThankingPage from "src/components/Login/ThankingPage";
import LoginHeader from "src/components/Login/LoginHeader";
import LoginFooter from "src/components/Login/LoginFooter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoginPage } from "src/redux/slices/LoginSlice";

const SignUpSuccessURL = () => {
  // const dispatch = useDispatch();
  // const login = useSelector((state) => state.login.loginPage);
  // const loginPageLocalSession = sessionStorage.getItem("loginPage");
  // if (loginPageLocalSession == "thanking") {
  //   dispatch(setLoginPage("thanking"));
  // }
  return (
    <div className="bg-light min-vh-100 d-flex flex-column justify-content-center SignInPage-Container">
      {/* <LoginHeader /> */}
      <div style={{ textAlign: "center", width: "100%" }}>
        <CRow className="justify-content-center">
          <CCol md={8} className="form">
            <CRow className="d-flex align-items-center">
              <ThankingPage />
            </CRow>
          </CCol>
        </CRow>
      </div>
      <LoginFooter />
    </div>
  );
};

export default SignUpSuccessURL;
