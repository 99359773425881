import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    accessToken: "",
    userName: "",
    projectID: "",
    sideBarShow: true,
    projectCount: [],
    typicalfloor: [],
    dashboardProgress: "",
    dashboardPipeline: "",
    dashboardCompleted: "",
    totalProjectsCount: 0,
    commonLoader: false,
    plan: "",
    planStartDate: "",
    planEndDate: "",
    planGBAvailable: "",
    planGBUsed: "",
    planAdditionalLicenses: "",
    planIsBlocked: "",
    planLastPaymentDate: "",
    mobile: "",
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setProjectID: (state, action) => {
      state.projectID = action.payload;
    },
    setSideBarShow: (state, action) => {
      state.sideBarShow = action.payload;
    },
    setProjectCount: (state, action) => {
      state.projectCount = action.payload;
    },
    setTypicalFloor: (state, action) => {
      state.typicalfloor = action.payload;
    },
    setDashboardProgress: (state, action) => {
      state.dashboardProgress = action.payload;
    },
    setDashboardPipeline: (state, action) => {
      state.dashboardPipeline = action.payload;
    },
    setDashboardCompleted: (state, action) => {
      state.dashboardCompleted = action.payload;
    },
    setTotalProjectsCount: (state, action) => {
      state.totalProjectsCount = action.payload;
    },
    setCommonLoader: (state, action) => {
      state.commonLoader = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setPlanStartDate: (state, action) => {
      state.planStartDate = action.payload;
    },
    setPlanEndDate: (state, action) => {
      state.planEndDate = action.payload;
    },
    setPlanGBAvailable: (state, action) => {
      state.planGBAvailable = action.payload;
    },
    setPlanGBUsed: (state, action) => {
      state.planGBUsed = action.payload;
    },
    setPlanAdditionalLicenses: (state, action) => {
      state.planAdditionalLicenses = action.payload;
    },
    setPlanIsBlocked: (state, action) => {
      state.planIsBlocked = action.payload;
    },
    setPlanLastPaymentDate: (state, action) => {
      state.planLastPaymentDate = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
  },
});

export const {
  setCommonLoader,
  setAccessToken,
  setUserName,
  setSideBarShow,
  setProjectID,
  setProjectCount,
  setTypicalFloor,
  setDashboardProgress,
  setDashboardPipeline,
  setDashboardCompleted,
  setTotalProjectsCount,
  setPlan,
  setPlanStartDate,
  setPlanEndDate,
  setPlanGBAvailable,
  setPlanGBUsed,
  setPlanAdditionalLicenses,
  setPlanIsBlocked,
  setPlanLastPaymentDate,
  setMobile,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
