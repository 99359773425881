import { createSlice } from "@reduxjs/toolkit";

const PlanSlice = createSlice({
  name: "Plans",
  initialState: {
    selectedPlan: null,
    selectedPlanDuration: null,
    selectedPlanAmount: 0,
    selectedPlanMail: null,
    pricingModalVisible: false,
    paymentModalVisible: false,
    planExceedModal: false,
    showBuyAdditionalUsersModal: false,
    planExceededModal: false,
    upgradePlansModal: false,
    upgradePaymentModal: false,
    selectedUpgradePlan: null,
    additionalAdminsCount: null,
    additionalTeamMembersCount: null,
    additionalAdminAmount: 1499,
    additionalTeamMemberAmount: 799,
    storageLimitReachedModal: false,
    buyAdditionalStorageModal: false,
    showAdditionalStorageForm: false,
    additionalStorageCount: 10,
    additionalStorageForStarterPerGB: 275,
    additionalStorageForBusinessPerGB: 250,
    lastBuyAdditionalStorageTxnId: null,
    lastBuyAdditionalMembersTxnId: null,
    selectedRenewalPlanDuration: 3,
    showRenewalPlanPaymentForm: false,
    lastRenewalTxnId: null,
    existingStaterPlanLeftDays: null,
    existingStarterPlanUpgradeAmount: null,
    isTodayOrPastPlanEndDate: null,
    lastUpgradeTxnId: null,
  },
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setSelectedPlanDuration: (state, action) => {
      state.selectedPlanDuration = action.payload;
    },
    setSelectedPlanAmount: (state, action) => {
      state.selectedPlanAmount = action.payload;
    },
    setSelectedPlanMail: (state, action) => {
      state.selectedPlanMail = action.payload;
    },
    setPricingModalVisible: (state, action) => {
      state.pricingModalVisible = action.payload;
    },
    setPaymentModalVisible: (state, action) => {
      state.paymentModalVisible = action.payload;
    },
    setPlanExceedModal: (state, action) => {
      state.planExceedModal = action.payload;
    },
    setShowBuyAdditionalUsersModal: (state, action) => {
      state.showBuyAdditionalUsersModal = action.payload;
    },
    setPlanExceededModal: (state, action) => {
      state.planExceededModal = action.payload;
    },
    setUpgradePlansModal: (state, action) => {
      state.upgradePlansModal = action.payload;
    },
    setUpgradePaymentModal: (state, action) => {
      state.upgradePaymentModal = action.payload;
    },
    setSelectedUpgradePlan: (state, action) => {
      state.selectedUpgradePlan = action.payload;
    },
    setAdditionalAdminsCount: (state, action) => {
      state.additionalAdminsCount = action.payload;
    },
    setAdditionalTeamMembersCount: (state, action) => {
      state.additionalTeamMembersCount = action.payload;
    },
    setAdditionalAdminAmount: (state, action) => {
      state.additionalAdminAmount = action.payload;
    },
    setAdditionalTeamMemberAmount: (state, action) => {
      state.additionalTeamMemberAmount = action.payload;
    },
    setStorageLimitReachedModal: (state, action) => {
      state.storageLimitReachedModal = action.payload;
    },
    setBuyAdditionalStorageModal: (state, action) => {
      state.buyAdditionalStorageModal = action.payload;
    },
    setShowAdditionalStorageForm: (state, action) => {
      state.showAdditionalStorageForm = action.payload;
    },
    setAdditionalStorageCount: (state, action) => {
      state.additionalStorageCount = action.payload;
    },
    setAdditionalStorageForStarterPerGB: (state, action) => {
      state.additionalStorageForStarterPerGB = action.payload;
    },
    setAdditionalStorageForBusinessPerGB: (state, action) => {
      state.additionalStorageForBusinessPerGB = action.payload;
    },
    setLastBuyAdditionalStorageTxnId: (state, action) => {
      state.lastBuyAdditionalStorageTxnId = action.payload;
    },
    setLastBuyAdditionalMembersTxnId: (state, action) => {
      state.lastBuyAdditionalMembersTxnId = action.payload;
    },
    setSelectedRenewalPlanDuration: (state, action) => {
      state.selectedRenewalPlanDuration = action.payload;
    },
    setShowRenewalPlanPaymentForm: (state, action) => {
      state.showRenewalPlanPaymentForm = action.payload;
    },
    setLastRenewalTxnId: (state, action) => {
      state.lastRenewalTxnId = action.payload;
    },
    setExistingStaterPlanLeftDays: (state, action) => {
      state.existingStaterPlanLeftDays = action.payload;
    },
    setExistingStarterPlanUpgradeAmount: (state, action) => {
      state.existingStarterPlanUpgradeAmount = action.payload;
    },
    setIsTodayOrPastPlanEndDate: (state, action) => {
      state.isTodayOrPastPlanEndDate = action.payload;
    },
    setLastUpgradeTxnId: (state, action) => {
      state.lastUpgradeTxnId = action.payload;
    },
  },
});

export const {
  setSelectedPlan,
  setSelectedPlanDuration,
  setSelectedPlanAmount,
  setSelectedPlanMail,
  setPricingModalVisible,
  setPaymentModalVisible,
  setPlanExceedModal,
  setShowBuyAdditionalUsersModal,
  setPlanExceededModal,
  setUpgradePlansModal,
  setUpgradePaymentModal,
  setSelectedUpgradePlan,
  setAdditionalAdminsCount,
  setAdditionalTeamMembersCount,
  setAdditionalAdminAmount,
  setAdditionalTeamMemberAmount,
  setStorageLimitReachedModal,
  setBuyAdditionalStorageModal,
  setShowAdditionalStorageForm,
  setAdditionalStorageCount,
  setAdditionalStorageForStarterPerGB,
  setAdditionalStorageForBusinessPerGB,
  setLastBuyAdditionalStorageTxnId,
  setLastBuyAdditionalMembersTxnId,
  setSelectedRenewalPlanDuration,
  setShowRenewalPlanPaymentForm,
  setLastRenewalTxnId,
  setExistingStaterPlanLeftDays,
  setExistingStarterPlanUpgradeAmount,
  setIsTodayOrPastPlanEndDate,
  setLastUpgradeTxnId,
} = PlanSlice.actions;
export default PlanSlice.reducer;
