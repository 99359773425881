import React from "react";
import Logo from "../../../src/assets/images/archiflask-logo-dark.png";
import { useDispatch, useSelector } from "react-redux";
import { setLoginPage } from "src/redux/slices/LoginSlice";

function LoginHeader() {
  const dispatch = useDispatch();
  const loginPageSession = localStorage.getItem("loginPage");
  const login = useSelector((state) => state.login.loginPage);
  return (
    <nav className="navbar w-100" style={{ position: "absolute", top: "0" }}>
      <div
        className="container-fluid mx-3"
        style={{ justifyContent: "space-between" }}
      >
        {/* <a className="navbar-brand">archiFlask</a> */}
        <a href="/">
          <img src={Logo} style={{ width: "40%" }}></img>
        </a>

        <div className="d-flex justify-content-around login-header_right">
          {login != "thanking" && (
            <button
              className="btn borderless-btn"
              onClick={() => {
                dispatch(
                  setLoginPage(login === "signUp" ? "signIn" : "signUp")
                );
              }}
            >
              {login === "signUp" ? "Sign In" : "Sign Up"}
            </button>
          )}
          <button
            className={
              login === "thanking"
                ? "btn btn-dark login-header_right-requestBtn login-header_right-watchBtn"
                : "btn btn-dark login-header_right-requestBtn"
            }
            type="submit"
          >
            {login === "thanking" ? "Watch Tutorials" : "Request Demo"}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default LoginHeader;
