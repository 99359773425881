import React, { useState } from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import LoginHeader from "./LoginHeader";
import LoginFooter from "./LoginFooter";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ThankingPage from "./ThankingPage";
import { useSelector } from "react-redux";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import { useDispatch } from "react-redux";

const LoginPage = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login.loginPage);
  const loginPageLocalSession = localStorage.getItem("loginPage");
  if (loginPageLocalSession == "thanking") {
    dispatch(setLoginPage("thanking"));
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-column justify-content-center SignInPage-Container">
      <LoginHeader />
      <div style={{ textAlign: "center", width: "100%" }}>
        <CRow className="justify-content-center">
          <CCol md={8} className="form">
            <CRow className="d-flex align-items-center">
              {login === "signUp" && <SignUp />}
              {login === "signIn" && <SignIn />}
              {login === "thanking" && <ThankingPage />}
              {/* <SignUp /> */}
              {/* <SignIn /> */}
              {/* <ThankingPage /> */}
            </CRow>
          </CCol>
        </CRow>
      </div>
      <LoginFooter />
    </div>
  );
};

export default LoginPage;
